import React from 'react';

import { translations } from './translations';
import { Container, Header, NewsCard, SEO, Footer } from '../../components';
//
import cssModule from './index.module.scss';

const News = ({ pageContext: ctx }) => {
  const { news, translationPath, metadata, i18n_lang: lang } = ctx;
  console.log({ news });
  return (
    <div className="news-page">
      <SEO {...metadata.metadata} />
      <Header
        {...{ lang, heading: translations[lang].heading }}
        lang={lang}
        translationPath={translationPath}
      />
      <section className={cssModule.news}>
        <Container>
          <div className={cssModule.newsGrid}>
            {news.map(item => (
              <NewsCard {...item} key={item.slug} lang={lang} hasBgColor={true} />
            ))}
          </div>
        </Container>
      </section>
      <Footer lang={lang} />
    </div>
  );
};

export default News;
